// Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted.

import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

let defaultApiUrl = `http://alta-voce.com`

const store = createStore({
  state: {
    currentLanguage: null,
    helpScoutBeaconId: process.env.VUE_APP_HELPSCOUT_BEACON_ID || 'ef7dc2e7-8011-4477-b143-c815d25e95c3',
    apiUrl: process.env.VUE_APP_API_URL || defaultApiUrl,
    authenticated: false,
    supportEmail: 'alta-call-support@alta-voce.tech',
    auth: {
      accessToken: null,
      refreshToken: null,
    },
    user: null,
    impersonation: {
      active: false,
      adminUser: null,
    },
    groups: [],
    selectedGroup: null,
    selectedgroupId: null,
    version: "1",
    organization: null,
    downloadsUrl: 'https://apps-download.alta-voce.tech/vmocall-any-AU5IlnnRQfPHDt69iBdzXNXjjNjZWMcH/dirlist_json_multi.php'
  },
  mutations: {
    authenticated (state, value) {
      state.authenticated = value
    },
    currentLanguage (state, value) {
      state.currentLanguage = value
    },
    auth (state, value) {
      state.auth = value
    },
    groups (state, value) {
      state.groups = value
    },
    organization (state, value) {
      state.organization = value
    },
    selectedGroup (state, value) {
      state.selectedGroup = value
      state.selectedGroupId = value ? value.id : null
    },
    selectBestGroup(state, value) {
      let groupByIds = {}
      state.groups.forEach((g) => {
        groupByIds[g.id] = g
      })
      if (state.groups.length === 0) {
        // should never happen
        state.selectedGroup = null
        state.selectedGroupId = null
        console.warn("No group available!")
      } else if (value && groupByIds[value]) {
        // current selected group exists, do nothing
        state.selectedGroupId = value
        state.selectedGroup = groupByIds[value]
        console.log("Currently selected group exists in list", JSON.stringify(state.selectedGroup), JSON.stringify(state.groups))
      }
      else {
        // select the first group in the list, we cannot do better
        console.log("Selecting first group of", JSON.stringify(state.groups))
        state.selectedGroup = state.groups[0]
        state.selectedGroupId = state.groups[0].id
      }
    },
    user (state, value) {
      state.user = value
    },
    impersonate (state, user) {
      state.impersonation.active = true
      console.log('Impersonating', user)
      state.impersonation.adminUser = state.user
      state.user = user
    },
    // lacking a better term ;)
    depersonate (state) {
      state.impersonation.active = false
      state.user = state.impersonation.adminUser
      state.impersonation.adminUser = null
    }
  },
  actions: {
    async handleLogin ({commit}, data) {
      commit('depersonate', {})
      commit(
        'auth',
        {accessToken: data.access_token, refreshToken: data.refresh_token}
      )
      commit(
        'authenticated',
        true
      )
      commit(
        'user',
        data.user
      )
    },
    async fetchGroups ({commit, state}, http) {
      if (!state.authenticated) {
        return
      }
      commit('groups', [])
      let response = await http.get("/groups")
      commit('groups', response.data.results)
    },
    async fetchOrganization ({commit, state}, http) {
      if (!state.authenticated) {
        return
      }
      commit('organization', null)
      let response = await http.get("organization")
      commit('organization', response.data)
    },
    logout ({commit}) {
      commit('depersonate', {})
      commit('user', null)
      commit('auth', {})
      commit('authenticated', false)
    }
  },
  modules: {
  },
  plugins: [
    new VuexPersistence({
      reducer: (state) => ({
        auth: state.auth,
        authenticated: state.authenticated,
        user: state.user,
        impersonation: state.impersonation,
        currentLanguage: state.currentLanguage,
        version: state.version,
        selectedGroup: state.selectedGroup,
        selectedGroupId: state.selectedGroupId,
      }),
    }).plugin
  ]
})

export default store

